import {
  Chart,
  LineSeries,
  Category,
  Tooltip,
  Legend,
  DataLabel,
} from "@syncfusion/ej2-charts";
import { registerLicense } from "@syncfusion/ej2-base";
import { Data } from "@syncfusion/ej2-charts/src/common/model/data";

Chart.Inject(LineSeries, Category, Tooltip, Legend, DataLabel);

class BurndownChart {
  constructor({ wrapper, data, title = "Gráfico de Burndown", data_inicial, data2 }) {
    registerLicense(
      "ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sdk1iWXtbcndcQmNc"
    );
    console.log("Licença Syncfusion registrada com sucesso.");

    try {
      if (!wrapper) {
        throw new Error("É necessário fornecer um wrapper para inicializar o Gráfico.");
      }
      // test
      this.$wrapper = $(wrapper);

      console.log("data", data)
      console.log("data2", data2)

      let ProgressoInicial = 100
      const serieIdeal = data.map((data) => {
        ProgressoInicial -= data.Esforco
        return {
          Data: data.Data,
          Ideal: Math.abs(ProgressoInicial) < 1e-10 ? 0 : ProgressoInicial
        }
      })

console.log("serieIdeal", serieIdeal)


let ProgressoInicialRealizado = 100
const serieRealizado = data2.map((data) => {
  ProgressoInicialRealizado -= data.Esforco
  return {
    Data: data.Data,
    Realizado: Math.abs(ProgressoInicialRealizado) < 1e-10 ? 0 : ProgressoInicialRealizado
  }
})
console.log("serieRealizada", serieRealizado)

console.log("daqui pra cima ta ok temos os datas e os progresso de ideal e realizado")


const bigdata = [...serieIdeal, ...serieRealizado]
console.log("bigdata", bigdata)

const sortedData = bigdata.sort((a, b) => new Date(a.Data) - new Date(b.Data));

sortedData.unshift({ Data: data_inicial, Ideal: 100, Realizado: 100 });

console.log("sortedData big", sortedData)


const consolidatedData = sortedData.reduce((acc, current) => {
  const lastItem = acc[acc.length - 1] || { Ideal: 100, Realizado: 100 }; // Valor inicial padrão

  acc.push({
    Data: current.Data,
    Ideal: current.Ideal !== undefined ? current.Ideal : lastItem.Ideal,
    Realizado: current.Realizado !== undefined ? current.Realizado : lastItem.Realizado,
  });

  return acc;
}, []);


console.log(consolidatedData);

const uniqueDatesData = consolidatedData.reduce((acc, current) => {
  // Verifica se já existe um objeto para a mesma data
  const existing = acc.find(item => item.Data === current.Data);

  if (existing) {
    // Atualiza apenas se o objeto atual for mais próximo de zero
    const isCurrentCloserToZero =
      Math.abs(current.Ideal) + Math.abs(current.Realizado) <
      Math.abs(existing.Ideal) + Math.abs(existing.Realizado);

    if (isCurrentCloserToZero) {
      existing.Ideal = current.Ideal;
      existing.Realizado = current.Realizado;
    }
  } else {
    // Adiciona ao acumulador caso não exista
    acc.push({ ...current });
  }

  return acc;
}, []);

console.log("uniqueDatesData", uniqueDatesData)

const processedData = consolidatedData.map((item, index, arr) => {
  const previous = arr[index - 1] || {Ideal: 100, Realizado: 100}; // Elemento anterior ou vazio

  console.log("previous do index 0", "index",index, previous)

  return {
    Data: item.Data,
    Ideal: item.Ideal !== undefined ? item.Ideal : previous.Ideal,
    Realizado: item.Realizado !== undefined ? item.Realizado : previous.Realizado,
  };
});

console.log("Processed Data:", processedData);

      // Criando o gráfico de Burndown
      const lineGrid = new Chart({
        primaryXAxis: {
          title: "Data de Entrega",
          valueType: "Category",
          interval: 1,
          labelPlacement: "OnTicks",
          
        },
        primaryYAxis: {
          title: "Progresso (%)",
          minimum: 0,
          maximum: 100,
          interval: 20,
        },
        series: [
          {
            dataSource: uniqueDatesData,
            xName: "Data",
            yName: "Ideal",
            name: "Ideal",
            type: "Line",
            marker: { visible: true },
          },{
            dataSource: uniqueDatesData,
            xName: "Data",
            yName: "Realizado",
            name: "Realizado",
            type: "Line",
            marker: { visible: true },
          },
        ],
        title,
        tooltip: { enable: true },
        legendSettings: { visible: true },
      });

      // Renderizando o gráfico no elemento
      lineGrid.appendTo(this.$wrapper[0]);
      console.log("Gráfico de Burndown renderizado com sucesso.");
    } catch (e) {
      console.error("Erro ao inicializar o Gráfico de Burndown:", e);
    }
  }
}

frappe.provide("frappe.burndown");
frappe.burndown.BurndownChart = BurndownChart;

export default BurndownChart;
